import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../modules/layout"
import SimpleHero from "../../modules/simpleHero"
import Breadcrumbs from "../../modules/breadcrumbs"
import Grid from "../../modules/grid"
import Pagination from "../../components/pagination"

class NewsPage extends Component {
  render() {
    const { data, pageContext, location } = this.props
    const { currentPage, newsPages } = pageContext
    const title = `Newsroom`
    const description = null
    const image = null
    const canonical = `${data.sanitySiteConfig.url}/newsroom/`

    return (
      <Layout>
        <Helmet>
          <meta name="robots" content="noindex" />
          <link rel="canonical" href={canonical} />
        </Helmet>
        <SEO title={title} description={description} image={image} />
        <Breadcrumbs location={location}>
          <Link to="/">Home</Link>
          <p>Newsroom</p>
        </Breadcrumbs>
        <SimpleHero heading="The PeopleFluent Newsroom" />
        <section className="blocksGrid">
          {data.allSanityNewsroom.edges.length && (
            <Grid blocks={data.allSanityNewsroom.edges} moreCols />
          )}
          {newsPages > 1 && (
            <Pagination
              type="newsroom"
              currentPage={currentPage}
              totalPages={newsPages}
            />
          )}
        </section>
      </Layout>
    )
  }
}

export default NewsPage

export const query = graphql`
  query($skip: Int, $limit: Int) {
    sanitySiteConfig {
      url
    }
    allSanityNewsroom(
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          _type
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          slug {
            current
          }
          excerpt
        }
      }
    }
  }
`
